import React from "react"
import { Container, Grid, Message, Icon, Divider, Button } from "semantic-ui-react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import ImageModal from "../components/ImageModal"
import Decoration from "../components/Decoration"
import launcher from "../images/launcher.png"

const GetStartedPage = () => (
  <Layout id="pages-map">
    <SEO title="Get started" description="All the information you need to get started playing on Slashdiablo, download the game launcher or install the game manually by following the legacy guide" />
    <Header active="get-started" />
    <Container>
      <Decoration content="Get started" />
      <Grid className="getting-started-container" container stackable>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <h2 className="serif">1. Install Diablo II Lord of destruction</h2>
            <p>Before you can start playing on Slashdiablo you need to install Diablo II Lord of Destruction, then patch to 1.13c with the launcher or download and install the patch manually.</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Message color="black" icon>
              <Icon name="info" />
              <Message.Content>
                <Message.Header>CD-key</Message.Header>
                You have to provide a valid CD-key for Diablo II during
                installation, any CD-key will do.
          </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid className="getting-started-container" container stackable>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <h2 className="serif">2. Install game launcher</h2>
            <div className="gallery-container">
              <div className="gallery">
                <ImageModal img={launcher} />
              </div>
              <div className="frame"></div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <h3 className="serif"><Icon name="windows" size="large" fitted /> Windows</h3>
            <p>Slashdiablo provides a game launcher for Windows that will take care of patching to 1.13c, gateway changes and let you choose from the different mods we allow</p>

            <Button as="a" href="https://github.com/nokka/slashdiablo-launcher/releases/download/v1.1.2/SlashDiablo_Installer_1.1.2.msi" color="red">
              <Icon name="download" />Slashdiablo Launcher
            </Button>

            <Divider />
            <h3 className="serif"><Icon name="apple" size="large" fitted /> OSX</h3>
            <p>For Mac we don't have a launcher yet but there's a <a href="https://www.reddit.com/r/slashdiablo/comments/58jify/diablo_2_lod_on_mac_20_includes_wineskin_tabbed/" target="_blank" rel="noopener noreferrer">setup guide</a> available to get started.</p>
            <Divider />
            <h3 className="serif"><Icon name="linux" size="large" fitted /> Linux</h3>
            <p>For Linux we don't have a launcher yet but there's a <a href="https://github.com/SlashFap/SlashDiabloOnLinux/wiki/SlashDiablo-on-Linux-:-Diablo-II-setup" target="_blank" rel="noopener noreferrer">setup guide</a> available to get started.</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Layout>
)

export default GetStartedPage
